import React from 'react'

const Service = () => {
  return (
    <div>
        Service here....
    </div>
  )
}

export default Service
