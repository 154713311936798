
const scrollReveal = (direction, delay) => {
    return {
        hidden: {
            opacity: 0,
            y: direction,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                delay: delay,
            },
        }
    }
}

export default scrollReveal