import React from 'react'
import './moreInfo.css'
import { MdClose } from "react-icons/md";
import { motion } from 'framer-motion';
import imageTextVariants from '../../animations/imageTextVariants';

const MoreInfo = ({onClose}) => {
  return (
    <div className='more-infor'>
        <motion.div className='more-info-container'
            variants={imageTextVariants('right', 0)}
            initial="hidden"
            animate="visible"
        >
            <div onClick={()=>onClose()} className='button'>
                 <MdClose size={25} />
            </div>
            <div className='more-info-detail'>
                <p>Explore more information about maunacil products</p>
            </div>
        </motion.div>
    </div>
  )
}

export default MoreInfo