import { Outlet } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import ComingSoon from './pages/ComignSoon';




function App() {
  return (
    <div className="App">
      <>
        {/* <Navbar/> */}
        <div>
            {/* <Outlet/> */}
            <ComingSoon />
        </div>
        {/* <Footer/> */}
      </>
    </div>
  );
}

export default App;
