import React from 'react'
import house from '../Assets/house2.jpg'
import './CSS/ComingSoon.css'

const ComingSoon = () => {
  return (
    <div className='coming-soon'>
        <div className='image-container'>
            <img src={house} alt='Background' />
        </div>
        <div className='text-container'>
            <div className='top'>
                <p>Maunacil Paints and Chemicals</p>
            </div>

            <div className='middle'>
                <p><span>Maintenace mode is on</span></p>
                <p>Site will be available soon. Thank you for your patience!</p>
            </div>

            <div className='bottom'>
                 <p>@ Maunacil Paints and Chemicals 2024</p>
            </div>
        </div>
    </div>
  )
}

export default ComingSoon