import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import { CgMenuGridR } from "react-icons/cg";
import { MdClose } from "react-icons/md";
import { RiHome9Line } from "react-icons/ri";




const Navbar = () => {
  return (
    <div className='navbar'>

        {/* Navbar Top Section */}
        <div className='nav-top'>
            <h2>Maunacil Paints and Chemicals</h2>
            <div className='contacts'>
                <div className='tel'><b>Tel:</b>08035417449 </div>
                <div className='email'><b>Email:</b> maunacilpaints@gmail.com</div>
            </div>
        </div>

        {/* Navbar Menu List Items */}
        <div className='nav-menu'>
            <div className='menu-icon'>
                 <CgMenuGridR size={30} />
                 <MdClose size={30} />
            </div>
            <div className='navbar-menu-item-list'>
                <Link style={{ textDecoration: 'none'}} to={'/'}><div className='navbar-menu-item home'>
                   <RiHome9Line size={30} />
                   <p>Maunacil</p>
                </div></Link>
                <Link style={{ textDecoration: 'none'}} to={'/about-us'}><p className='navbar-menu-item'>About us</p></Link>
                <Link style={{ textDecoration: 'none'}} to={'/service'}><p className='navbar-menu-item'>Service</p></Link>
                <Link style={{ textDecoration: 'none'}} to={'/products'}><p className='navbar-menu-item'>Products</p></Link>
                <Link style={{ textDecoration: 'none'}} to={'/contact'}><p className='navbar-menu-item'>Contact us</p></Link>
            </div>
        </div>
    </div>
  )
}

export default Navbar
