import React from 'react'

const Gallery = () => {
  return (
    <div>
        Photo Gallery here...
    </div>
  )
}

export default Gallery
