import React from 'react'
import { motion } from 'framer-motion'

const AboutUs = () => {
  return (
    <motion.div animate={{ fontSize: 300}}>
       About Us Page here...
    </motion.div>
  )
}

export default AboutUs
