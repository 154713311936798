
const containerVariants = (direction, delay) => {
    return {
        hidden: {
            opacity: 0,
            y: direction,
          },
          visible: {
            opacity: 1,
            y: 0,
            transition: {
              delay: delay,
              type: 'spring',
              stiffness: 100,
              duration: 3.5,
              easeIn: 1.5,
            }
          }
    }
}

export default containerVariants