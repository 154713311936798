import React, { useState } from 'react'
import './CSS/Home.css'
import slide from '../Assets/slide3.jpg'
import crieta from '../Assets/crieta.jpg'
import texture from '../Assets/texture.jpg'
import house from '../Assets/house.jpg'
import buttonVariants from '../animations/buttonVariants'
import  imageTextVariants  from '../animations/imageTextVariants'
import containerVariants from '../animations/containerVariants'
import scrollReveal from '../animations/scrollReveal'
import { FaPaintRoller } from "react-icons/fa";
import { LuPaintBucket } from "react-icons/lu";
import { motion} from 'framer-motion'
import grid1 from '../Assets/grid1.jpg'
import grid2 from '../Assets/grid2.jpg'
import grid3 from '../Assets/grid3.jpg'
import grid4 from '../Assets/grid4.jpg'
import color_pallet from '../Assets/palett-hd.png'
import MoreInfo from '../components/helper/moreInfo'






const Home = () => {

    const [more, setMore] = useState(false);

  return (
    <div className='home'>

       {/* Slider */}
       <div className='slide'>
            <img src={slide}  alt='slide'/>

            <div className='image-text-container'> 
                <motion.div className='image-text left'
                    variants={imageTextVariants('left', 0.5)}
                    initial="hidden"
                    animate="visible"
                  >
                        <h2 className='image-text-heading left'> Revives Your Space</h2>
                        <p className='image-text-desc'>Over time, walls can show wear and tear, 
                          leaving your home looking tired and lifeless. A fresh coat of paint 
                          breathes new energy into your home, making it feel fresh and welcoming.</p>
                </motion.div>

                <motion.div className='image-text right'
                    variants={imageTextVariants('right', 1.0)}
                    initial="hidden"
                    animate="visible"
                  >
                        <h2 className='image-text-heading right'> Boosts Mood and Creativity</h2>
                        <p className='image-text-desc'>Colors have a profound psychological effect. 
                          Soft blues and greens bring calm, vibrant yellows and oranges energize, 
                          and neutral tones create a timeless elegance. The colors you choose set 
                          the tone for every moment spent in your home.</p>
                </motion.div>
            </div>
       </div>

        {/* feature Text */}
       <div className='middle-feature-text-container'>
              
              <motion.div className='middle-feature-text left'
                  variants={scrollReveal(200, 0.1)}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{once: true}}
              >
                  <h2>The Power of House Paint</h2>
                  <p>House paint is more than just a coating on walls, 
                    it's the soul of your living space, a powerful medium 
                    of self-expression, and a transformative tool that can 
                    elevate your home into a sanctuary of comfort and beauty. 
                    Whether you are looking to refresh a single room or revamp 
                    the entire house, the right paint can make a world of difference.</p>
                    <div><LuPaintBucket size={40} /></div>
              </motion.div>

              <motion.div className='middle-feature-text right'
                  variants={scrollReveal(200, 0.1)}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{once: true}}
              >
                  <h2>A Home That Reflects You</h2>
                  <p>Painting is not just a chore; it's an act of renewal. 
                    It's a chance to turn the ordinary into the extraordinary, 
                    to infuse your personality into your surroundings. Whether 
                    you do it yourself or hire professionals, watching your 
                    vision come to life is incredibly satisfying.</p>
                    <div><FaPaintRoller size={40} /></div>
              </motion.div>

        </div>


        {/* Middle Container */}
        <div className='middle'>
              {/* middle container 1 */}
           <motion.div  className='middle-container crieta'
              variants={scrollReveal(200, 0.1)}
              initial="hidden"
              whileInView="visible"
              viewport={{once: true}}
            >

                <div className='middle-container-left'>
                    <img  src={crieta} alt='crieta' />
                </div>

                <div className='middle-container-right'>
                    <h2>Luminate Crieta Colour of the Year 2025 - Showing the way to Colour Tech</h2>
                    <motion.div className='middle-container-desc'
                        variants={containerVariants(100, 0.8)}
                        initial="hidden"
                        animate="visible"
                    >
                        Every year, Luminate Colour experts translate Global design trends 
                        into the new Colour of the year. The shade for 2025 is True Joy. 
                        It is an uplifting Colour that fills your homes with creativity, 
                        optimism and pride.
                    </motion.div>
                    <motion.button
                        variants={buttonVariants()}
                        whileHover="hover"
                        transition={{ duration: 1.0 }}
                        onClick={()=>setMore(true)}
                    > 
                        Discover More...
                    </motion.button>
                </div>
          </motion.div>


            {/* middle container 2 */}
            <motion.div className='middle-container house'
              variants={scrollReveal(200, 0.1)}
              initial="hidden"
              whileInView="visible"
              viewport={{once: true}}
            >

                    <div className='middle-container-left'>
                        <img  src={house} alt='crieta' />
                    </div>

                    <div className='middle-container-right'>
                        <h2>At Luminate We handle painting job and give your building the look they deserve</h2>
                        <motion.div className='middle-container-desc'
                            variants={containerVariants(100, 1.8)}
                            initial="hidden"
                            animate="visible"
                        >
                            Your home is a canvas, and house paint is your brush. With every stroke, 
                            you can create a masterpiece that reflects your unique style, dreams, 
                            and aspirations. A fresh coat of paint is more than a change in color—it's 
                            a transformation of the heart and soul of your home.
                        </motion.div>
                        <motion.button 
                            variants={buttonVariants()}
                            whileHover="hover"
                            transition={{ duration: 1.0 }}
                            onClick={()=>setMore(true)}
                         > 
                         Discover More...
                        </motion.button>
                    </div>
            </motion.div>


            <motion.div className='grid'
                variants={scrollReveal(200, 0.1)}
                initial="hidden"
                whileInView="visible"
                viewport={{once: true}}
            >

                    <div className='grid-item'>
                        <img src={grid1} alt='' />
                        <div className='grid-desc'>
                            <p>Best finishing is the result of quality paints product, and professional painters.</p>
                        </div>
                    </div>

                    <div className='grid-item'>
                        <img src={grid4} alt='' />
                        <div className='grid-desc'>
                            <p>Maunacil Luminate is a brand with unique formulation, for best delivery.</p>
                        </div>
                    </div>

                    <div className='grid-item'>
                        <img src={grid3} alt='' />
                        <div className='grid-desc'>
                            <p>No mater the height, our products and painters got's you cover.</p>
                        </div>
                    </div>

                    <div className='grid-item'>
                        <img src={grid2} alt='' />
                        <div className='grid-desc'>
                            <p>Maunacil Luminate silkcoat paint gives your interior the best grousing look.</p>
                        </div>
                    </div>

            </motion.div>

                        {/* middle container 3 */}
            <motion.div className='middle-container pallet'
              variants={scrollReveal(200, 0.1)}
              initial="hidden"
              whileInView="visible"
              viewport={{once: true}}
            >

                    <div className='middle-container-left pallet'>
                        <img  src={color_pallet} alt='pallet' />
                    </div>

                    <div className='middle-container-right pallet'>
                        <h2>Colors are the soul's of language, and a palette is its poetry.</h2>
                        <motion.div className='middle-container-desc'
                            variants={containerVariants(100, 1.8)}
                            initial="hidden"
                            animate="visible"
                        >
                           A color palette is more than a collection of shades; it's an 
                           orchestra of emotion, harmony, and creativity. Each hue holds 
                           the power to evoke memories, inspire dreams, and breathe life 
                           into the ordinary. Together, they transform vision into art and 
                           ideas into beauty.
                        </motion.div>
                        <motion.button 
                            variants={buttonVariants()}
                            whileHover="hover"
                            transition={{ duration: 1.0 }}
                            onClick={()=>setMore(true)}
                         > 
                         Discover More...
                        </motion.button>
                    </div>
            </motion.div>


        </div>
        
        {/* More information About Maunacil */}
       { 
            more  && (
                < MoreInfo onClose={()=>setMore(false)} />
            )
       }
    </div>
  )
}

export default Home
